.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 100;
  font-display: auto;
  // font-family: monospace, monospace;
  font-family: 'Roboto', sans-serif;
  position: relative;

  .sub {
    position: absolute;
    bottom: -9px;
    font-size: 10px;
    margin: auto;
    left: 0;
    right: -34px;
    white-space: nowrap;
  }

  img {
    height: 44px;
  }

  &__mark {
    color: #2fb3ac;
    font-weight: 500;
    margin-right: 5px;
    font-size: 1.1rem;
  }

  &__text {
    font-size: 1.325rem;
  }

  &__text span {
    font-weight: 500;
    margin-left: 5px;
  }

  &__cursor {
    display: inline-block;
    width: 10px;
    height: 1rem;
    background: #fdfdff;
    background: transparent;
    border-bottom: 2px solid #bd1717;
    margin-left: 5px;
    border-radius: 1px;
    animation: cursor 1s infinite;
  }

  @media (prefers-reduced-motion: reduce) {
    &__cursor {
        animation: none;
    }
  }

}

@keyframes cursor {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
